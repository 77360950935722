import React from "react";
import Layout from "../../components/layout/layout";
import { Button } from "@xometry/xometry_loft";
import { Base50x, Headline, Logo } from "./_50x.styled";

export default () => {
  const seo = {
    robots: "noindex",
  };

  return (
    <Layout seo={seo} isGuttered={true}>
      <Base50x>
        <Logo src={require("../../images/broken-xom-logo.png")} />
        <Headline>Sorry! Looks like something is broken!</Headline>
        <Button
          kind="text-primary"
          scale="large"
          onClick={() => window.history.back()}
        >
          Return to Previous Page
        </Button>
      </Base50x>
    </Layout>
  );
};
