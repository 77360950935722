import styled from "@emotion/styled";
import theme from "../../styles/theme";

export const Base50x = styled.div`
  text-align: center;
  color: #253842;
  max-width: 850px;
  margin: 0 auto;
`;

export const Logo = styled.img`
  margin: 0 auto;
  width: 100%;
  height: 100%;
  max-width: 420px;
  max-height: 418px;

  ${theme.smallerThan.medium} {
    width: 75%;
    height: 75%;
  }
`;

export const Headline = styled.h1`
  font-size: 28px;
  line-height: 36px;
  padding: 0 8%;
  color: #253842;
  margin-bottom: 36px;

  ${theme.smallerThan.medium} {
    font-size: 24px;
    line-height: 36px;
  }
`;
